<template>
  <section :class="tipeQuiz == 'duel' && leaderboardSeruSeruan.length < 3 ? 'leaderboard-quiz-duel' : 'leaderboard-quiz'">
      <div class="leaderboard-container">
          <div class="text-right container-md d-flex justify-content-between">
            <div class="timer-div d-flex justify-content-center">
                <div class="d-flex align-items-center text-right my-2">
                  <h4 class="text-white mb-0 mr-2 font-weight-normal">Time's Up </h4>
                  <span id="countdown" class="text-white h5">00:00:00</span>
                </div>
              </div>
              <b-button v-if="tipeQuiz == 'seru-seruan' || tipeQuiz == 'ramean' || tipeQuiz == 'duel'" pill variant="light" @click="$bvModal.show('akhiri-quiz')">
                  <div class="mx-2 my-1">
                      <b-icon icon="x"></b-icon> End Quiz
                  </div>
              </b-button>
              <b-button v-else pill variant="light" @click="$router.push({ name : 'Report' })">
                  <div class="mx-2 my-1">
                      <b-icon icon="x"></b-icon> End Quiz
                  </div>
              </b-button>
          </div>

          <div v-if="$route.query.key == 'ramean'" class="container-md mt-3 pb-5 d-flex justify-content-center">
              <div class="card-live-content">
                  <div class="mx-5 pt-4 text-left">
                      <h4 class="text-white">Leaderboard</h4>
                  </div>
                  <div class="mx-5 py-4">
                      <table>
                          <tr>
                              <th>Rank</th>
                              <th>Team</th>
                              <!-- <th width="50%">Statistik</th> -->
                              <th>Point</th>
                          </tr>
                          <tr v-for="(data, index) in leaderboardTeam" :key="index" class="card-table">
                              <td class="px-3 py-3"># {{ index+1 }}</td>
                              <td class="px-3 py-3">
                                  <div class="d-flex align-items-center">
                                      <img :src="require(`@/assets/images/users/default_t${index+1}.svg`)" alt="" class="img-board">
                                      <div class="text-white ml-2">
                                          <h5>{{ data.team_name }}</h5>
                                          <p class="text-muted mt-1 mb-0">{{ data.player_team_count }} Participant</p>
                                      </div>
                                  </div>
                              </td>
                              <!-- <td class="px-3 py-3">
                                  <b-progress class="mt-2" :max="max" show-value>
                                      <b-progress-bar :value="value * (data.benar / 10)" variant="success">Benar {{ value * (data.benar / 10) }}</b-progress-bar>
                                      <b-progress-bar :value="value * (data.salah / 10)" variant="danger">Salah {{ value * (data.salah / 10) }}</b-progress-bar>
                                  </b-progress>
                              </td> -->
                              <td class="px-3 py-3">{{bulatkan(data.all_score_team)}}</td>
                          </tr>
                      </table>
                  </div>
              </div>
          </div>

          <div v-else-if="$route.query.key == 'seru-seruan'" class="container-md mt-3 pb-5 d-flex justify-content-center">
              <div class="card-live-content">
                  <div class="mx-5 pt-4 d-flex justify-content-between align-items-center">
                      <h4 class="text-white text-left">Leaderboard</h4>
                  </div>
                  <div class="mx-5 py-4">
                      <table>
                          <tr>
                              <th>Rank</th>
                              <th>Participant</th>
                              <th width="50%">Statistic</th>
                              <th>Point</th>
                          </tr>
                          <tr v-for="(data, index) in leaderboardSeruSeruan" :key="index" class="card-table">
                              <td class="px-3 py-3">
                                <p class="mb-0"># {{ index+1 }}</p>
                              </td>
                              <td class="px-3 py-3">
                                  <div class="d-flex align-items-center">
                                      <img :src="'https://ui-avatars.com/api/?background=405DC3&color=fff&name=' + data.user_name" alt="" class="img-board rounded-circle">
                                      <div class="text-white ml-3">
                                          <p class="mb-0">{{ data.user_name }}</p>
                                      </div>
                                  </div>
                              </td>
                              <td class="px-3 py-3">
                                  <b-progress class="mt-2" :max="data.data_all_answer.length" show-value>
                                      <b-progress-bar :value="data.data_all_answer.length * (data.data_true_answer.length / data.data_all_answer.length)" variant="success">Correct {{ data.data_true_answer.length }}</b-progress-bar>
                                      <b-progress-bar :value="data.data_all_answer.length * (data.data_false_answer.length / data.data_all_answer.length)" variant="danger">Incorrect {{ data.data_false_answer.length }}</b-progress-bar>
                                  </b-progress>
                              </td>
                              <td class="px-3 py-3">{{data.score}}</td>
                          </tr>
                      </table>
                  </div>
              </div>
          </div>

          <div v-else class="container-md mt-3 pt-3 pb-5 d-flex justify-content-center align-items-center">
            <div class="card-live-content">
                  <div class="mx-5 pt-4 text-left" :class="leaderboardDuel.length > 0 ? '' : 'mb-4'">
                      <h4 class="text-white">Leaderboard</h4>
                  </div>
                  <div v-if="leaderboardDuel.length>0" class="mx-5 py-4">
                    <div  class="d-flex justify-content-center align-items-center position-relative">
                      <div class="position-absolute d-flex justify-content-center">
                          <img src="@/assets/images/logo/versus.svg" alt="">
                      </div>
                      <b-progress height="3rem" :value="leaderboardDuel[0].team_score" :max="maxDuel" show-value class=" w-100 d-flex flex-row-reverse "></b-progress>
                      <b-progress height="3rem" :value="leaderboardDuel[1].team_score" variant="warning" :max="maxDuel" show-value class=" w-100"></b-progress>
                    </div>
                    <div class="d-flex justify-content-between pt-4 pb-5">
                      <div>
                          <h4 class="text-white">Duel 1</h4>
                          <div v-for="(data, index) in leaderboardDuel[0].data_player_duel" :key="index" class="d-flex align-items-center mt-3">
                            <img :src="'https://ui-avatars.com/api/?background=405DC3&color=fff&name=' + data.user_name" class="img-peserta rounded-circle" alt="">
                            <p class="mb-0 ml-2 text-white">{{ data.user_name }}</p>
                        </div>
                      </div>
                      <div>
                        <h4 class="text-white">Duel 2</h4>
                        <div v-for="(data, index) in leaderboardDuel[1].data_player_duel" :key="index" class="d-flex align-items-center mt-3">
                            <img :src="'https://ui-avatars.com/api/?background=405DC3&color=fff&name=' + data.user_name" class="img-peserta rounded-circle" alt="">
                            <p class="mb-0 ml-2 text-white">{{ data.user_name }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
      </div>

      <b-modal id="akhiri-quiz" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="sm">
          <div class="float-right">
              <button class="btn d-flex justify-content-center align-items-center pr-0" @click="$bvModal.hide('akhiri-quiz')"><img src="@/assets/images/icons/ic_close.svg" alt=""></button>
          </div>
          <div class="mt-5 text-center mb-3">
              <img src="@/assets/images/icons/modal_warning.svg" alt="End Quiz">
              <div class="mt-3">
                  <h5 class="font-weight-bold text-white">Are you sure you want to </h5>
                  <h5 class="font-weight-bold text-white">end this quiz?</h5>
              </div>
              <div class="d-flex justify-content-center mt-4">
                  <button class="btn btn-secondary text-white mr-2" @click="$bvModal.hide('akhiri-quiz')"><p class="mb-0 text-white font-weight-bold">Cancel</p></button>
                  <button class="btn btn-primary btn-purple-nb" @click="akhiriQuiz()"><p class="mx-2 mb-0 text-white font-weight-bold">Yes</p></button>
              </div>
          </div>
      </b-modal>
  </section>
</template>
<script>
import "element-ui/lib/theme-chalk/index.css";
import "element-tiptap/lib/index.css";
import $ from "jquery";
import {getQuizResultsByEvent} from '@/services/quiz-results/quiz-results.service'
import {getQuizTeamLeaderboard} from '@/services/quiz-results/quiz-results.service'
import {getQuizDuelLeaderboard} from '@/services/quiz-results/quiz-results.service'
import io from "socket.io-client"
import {base_url_socket} from '@/config/base_url'

export default {
  data() {
    return {
      socket : io(base_url_socket),
      tipeQuiz : null,
      max: 10,
      value : 10,
      maxDuel: 100,
      valueDuel : 400,
      additional : false,
      listQuestion:[],
      leaderboardSeruSeruan : [],
      leaderboardTeam : [],
      leaderboardDuel : [],
      time_limit: 0,
      quiz : false,
      polling : false,
      training : false,
      timer : null,
      intervalExam: null,
      formData: {
        grade_id: null,
        category_id: null,
        learning_category_id: null,
        quiz_name: null,
      },
      codeQuiz : null,
      link : 'https://soca.ai/join?gc=808080',
      kode : '808080',
      shareVia : [
        {
            logo : 'wa.png',
            link : 'https://web.whatsapp.com/send?text=www.google.com'
        },
        {
            logo : 'fb.png',
            link : 'https://www.facebook.com/'
        },
        {
            logo : 'ig.png',
            link : 'https://www.instagram.com/'
        },
        {
            logo : 'linkedin.png',
            link : 'https://www.linkedin.com/'
        },
        {
            logo : 'gmail.png',
            link : 'https://mail.google.com/'
        },

    ],
    timPeserta : [
        {
            namaTim : 'Tim Serigala',
            benar : 10,
            salah : 0,
        },
        {
            namaTim : 'Tim Harimau',
            benar : 8,
            salah : 2
        },
        {
            namaTim : 'Tim Serigala',
            benar : 7,
            salah : 3,
        },
        {
            namaTim : 'Tim Serigala',
            benar : 5,
            salah : 5,
        },
    ],
    peserta : [
      {
        namaPeserta : 'Asep Balon',
        benar : 10,
        salah : 0,
      },
      {
        namaPeserta : 'Usman Karbon',
        benar : 5,
        salah : 5,
      },
      {
        namaPeserta : 'Diablo Doglo',
        benar : 5,
        salah : 5,
      },
      {
        namaPeserta : 'Laksamana Pipit',
        benar : 5,
        salah : 5,
      },
    ],
    pesertaDuel : [
        {
            nama : 'Isman Karbon',
            team_score : "100",
            data_player_duel : [
              {
                user_name : "dadang kasbon"
              },
              {
                user_name : "dadang kasbon"
              },
             ]
        },
        {
            nama : 'Nanang konelo',
            team_score : "100",
            data_player_duel : [
              {
                user_name : "dadang kasbon"
              },
              {
                user_name : "dadang kasbon"
              },
             ]
        },
    ]

    };
  },
  methods: {
    setSideBar() {
      this.$store.commit("IS_SIDEBAR_ACTIVE", false);
    },

    copy(toaster, append = false){
        let vm = this
        vm.$refs.clone.focus();
        document.execCommand('copy');
        vm.$bvToast.toast(`Berhasil Copy Link !`, {
        title: `Alert`,
        toaster: toaster,
        solid: true,
        appendToast: append
        })

        let codeCopy = document.querySelector('#link-quiz')
        codeCopy.setAttribute('type', 'text')
        codeCopy.select()
    },

    durasi(sisa_waktu) {
            var vm = this;
            let timePassed = 0;
            vm.time_limit = sisa_waktu;

            let timeLeft = vm.time_limit;

            document.getElementById("countdown").innerHTML = `
                <span id="base-timer-label">${formatTime(timeLeft)}</span>
            `;
            if(timeLeft !== 0){
              startTimer();
            }
            var time_limit = vm.time_limit;

            function startTimer() {
                vm.intervalExam = setInterval(() => {
                    // vm.checkConnectionSpeed();
                    // vm.checkConnectionRealtime();
                    timePassed = timePassed + 1;
                    timeLeft = time_limit - timePassed;
                    // Getting the input from user.
                    document.getElementById("base-timer-label").innerHTML = formatTime(
                        timeLeft
                    );
                    vm.sisaWaktu = $('#countdown span').text();
                    vm.timeRunning = vm.time_limit-timeLeft
                    localStorage.setItem('sisaWaktu', timeLeft)

                    --timeLeft;
                    if(timeLeft === 0){
                      // aman
                      if(vm.tipeQuiz == "seru-seruan"){

                        const id = vm.$route.query.id
                        vm.socket.emit("end_quiz", { message: vm.codeQuiz })
                        vm.$router.push({ name : 'Leaderboard-Final', query : {id : id, key : 'seru-seruan'} })
                        localStorage.removeItem("sisaWaktu")
                        localStorage.removeItem("quizTime")
                        clearInterval(vm.intervalExam)

                      } else if(vm.tipeQuiz == "ramean"){

                        const id = vm.$route.query.id
                        vm.socket.emit("end_quiz", { message: vm.codeQuiz })
                        vm.$router.push({ name : 'Leaderboard-Final', query : {id : id, key : 'ramean'} })
                        localStorage.removeItem("sisaWaktu")
                        localStorage.removeItem("quizTime")
                        clearInterval(vm.intervalExam)

                      } else if(vm.tipeQuiz == "duel") {

                        const id = vm.$route.query.id
                        vm.socket.emit("end_quiz", { message: vm.codeQuiz })
                        localStorage.removeItem("sisaWaktu")
                        localStorage.removeItem("quizTime")
                        clearInterval(vm.intervalExam)
                        vm.$router.push({ name : 'Leaderboard-Final', query : {id : id, key : 'duel'} })

                      } else {
                        vm.socket.emit("end_quiz", { message: vm.codeQuiz })
                        localStorage.removeItem("sisaWaktu")
                        localStorage.removeItem("quizTime")
                        vm.$router.push({ name : 'Report' })
                        clearInterval(vm.intervalExam)
                      }
                    }
                }, 1000);
            }

            function formatTime(time) {
                var minutes = Math.floor(time / 60);
                let seconds = Math.round(((time / 60) - Math.floor(time / 60)) * 60);

                var hrs = Math.floor(minutes / 60);
                minutes = minutes % 60;

                if (hrs < 10) {
                hrs = `0${hrs}`;
                }

                if (minutes < 10) {
                minutes = `0${minutes}`;
                }

                if (seconds < 10) {
                seconds = `0${seconds}`;
                }

                return `${hrs}:${minutes}:${seconds}`;
            }

        },

    chooseType(type){
      if(type == 'quiz'){
        this.quiz = true;
        this.polling = false;
        this.training = false;
      } else if(type == 'polling'){
        this.quiz = false;
        this.polling = true;
        this.training = false;
      } else {
        this.quiz = false;
        this.polling = false;
        this.training = true;
      }

    },
    addQuiz(){
      localStorage.removeItem("listQuestionCreator");
      localStorage.removeItem("newListQuestionCreator")
      this.$router.push({ name: 'Edit-My-Quiz'})
    },

    onCopy: function (e) {
        alert('Berhasil Copy link : ' + e.text)
    },

    onError: function (e) {
        alert('Gagal untuk copy link !')
        console.log(e);
    },

    getLeaderboard(){
      const id = this.$route.query.id
      getQuizResultsByEvent(id)
      .then((response) => {
        if (response.status && response.status == true){
          this.leaderboardSeruSeruan = response.data
          // console.log(response)
        } else {
          console.log(response)
        }
      })
    },

    getLeaderboardRamean(){
      const id = this.$route.query.id
      getQuizTeamLeaderboard(id)
      .then((response) =>{
        if(response.status && response.status == true){
          this.leaderboardTeam = response.data
        }
      })
    },

    getLeaderboardDuel(){
      const id = this.$route.query.id
      getQuizDuelLeaderboard(id)
      .then((response) => {
        if(response.status && response.status == true){
          this.leaderboardDuel = response.data
        }
      })
    },

    bulatkan(item){
      var pembulatan = Math.ceil(item)

      return pembulatan
    },

    akhiriQuiz(){
            if(this.tipeQuiz == 'seru-seruan'){
              const id = this.$route.query.id
              this.socket.emit("end_quiz", { message: this.codeQuiz })
              localStorage.removeItem("sisaWaktu")
              localStorage.removeItem("quizTime")
              clearInterval(this.intervalExam)
              this.$router.push({ name : 'Leaderboard-Final', query : {id : id, key : 'seru-seruan'} })
            } else if (this.tipeQuiz == 'ramean') {

              const id = this.$route.query.id
              this.socket.emit("end_quiz", { message: this.codeQuiz })
              localStorage.removeItem("sisaWaktu")
              localStorage.removeItem("quizTime")
              clearInterval(this.intervalExam)
              this.$router.push({ name : 'Leaderboard-Final', query : {id : id, key : 'ramean'} })

            } else if (this.tipeQuiz == 'duel') {

              const id = this.$route.query.id
              this.socket.emit("end_quiz", { message: this.codeQuiz })
              localStorage.removeItem("sisaWaktu")
              localStorage.removeItem("quizTime")
              clearInterval(this.intervalExam)
              this.$router.push({ name : 'Leaderboard-Final', query : {id : id, key : 'duel'} })

            } else {
              this.socket.emit("end_quiz", { message: this.codeQuiz })
              localStorage.removeItem("sisaWaktu")
              localStorage.removeItem("quizTime")
              clearInterval(this.intervalExam)
              this.$router.push({ name : 'Report' })
            }
    }

  },
  computed: {
    hasAdditional(){
        return this.opsi1.length >0;
    }
  },
  mounted() {
    $("section.leaderboard-quiz").parent().addClass("m-0");
    this.setSideBar();

    const tipe = this.$route.query.key
    this.tipeQuiz = tipe;

    const code = this.$route.query.gc
    this.codeQuiz = code

    if(localStorage.getItem("sisaWaktu")) {
      this.time_limit = localStorage.getItem("sisaWaktu");
      this.durasi(localStorage.getItem("sisaWaktu"))
    } else if(localStorage.getItem("quizTime")) {
      this.durasi(localStorage.getItem("quizTime"))
      if(localStorage.getItem("sisaWaktu")){
        localStorage.removeItem("sisaWaktu")
      }
    }

    if(tipe == 'seru-seruan'){
        this.timer = setInterval(() =>{
            this.getLeaderboard()
        }, 5000)
    } else if(tipe == 'ramean') {
        this.timer = setInterval(() =>{
            this.getLeaderboardRamean()
        }, 5000)
    } else {
        this.timer = setInterval(() =>{
            this.getLeaderboardDuel()
        }, 5000)
    }
  },
  beforeDestroy(){
    clearInterval(this.timer)
  }
};
</script>

<style scoped>
.card-live-header{
    border-radius: 20px;
    width: 65%;
    background: rgba(0, 0, 0, 0.5);
    position: relative;
}

.timer-div{
  width: 25%;
  border-radius: 20px;
  background: rgba(12, 0, 0, 0.6);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8.3px);
    -webkit-backdrop-filter: blur(8.3px);
    border: 1px solid rgba(12, 0, 0, 0.3);
}

.timer-div p{
  font-size: 24px;
}

.timer-div span{
  font-size: 22px;
}
.card-live-content{
    border-radius: 20px;
    width: 100%;
    background: rgba(12, 0, 0, 0.6);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8.3px);
    -webkit-backdrop-filter: blur(8.3px);
    border: 1px solid rgba(12, 0, 0, 0.3);
    position: relative;
}

table{
    width: 100%;
    table-layout: auto;
}

td, th{
    color: white;
    text-align: left;
}

.card-table{
    background: rgba(0, 0, 0, 0.8);
    position: relative;
}

td:first-child,
th:first-child {
  border-radius: 10px 0 0 10px;
}

td:last-child,
th:last-child {
  border-radius: 0 10px 10px 0;
}
.card-peserta{
    border-radius: 20px !important;
    background: rgba(0, 0, 0, 0.8);
    position: relative;
    height:280px
}

th,
td {
  padding: 10px;
  background-clip: padding-box;
  border-bottom: 15px solid transparent;
  border-radius: 20px solid transparent;
}

.leaderboard-container{
    padding-top: 100px;
}

.dot{
    height: 5vh;
    width: 5vh;
    border-radius:50%;
    display: inline-block;
    background-color: #fff;
}

.img-board{
    width: 51px;
    height: 51px;
}

.img-peserta{
    width: 23px;
    height: 23px;
}
</style>

<style>
html {
  height: 100% !important;
}
body {
  height: 100% !important;
}
#app {
  height: 100% !important;
}
.main-wrapper {
  height: 100% !important;
}
.main-container-fluid {
  /* height: 100% !important;
  margin-left: 0px !important;
  padding: 0 !important; */
}
.leaderboard-quiz {
  min-height: 100%;
  width: 100%;
  background-size: cover;
  background-image: url("../../../assets/images/background/bg-space-web.png");
}

.leaderboard-quiz-duel {
  min-height: 100%;
  width: 100%;
  background-size: cover;
  background-image: url("../../../assets/images/background/bg-space-web.png");
}

.container-content{
    padding-top:20vh ;
    left: 30vw;
    width: 50%;
    margin-right: 35rem;
    z-index: 3;
    position: relative;
}
#scene-container-content {
  width: 100%;
  height: 110%;
  position: absolute;
  left: -25vw;
  /* top: 5vh; */
}
#scene-container-content div {
  display: none !important;
}
.container-filter {
  background: rgba(0, 0, 0, 0.5);
  position: relative;
}
.container-question {
  width: 67.5%;
}
.konten-text{
    background: #E29CFF;
    border-radius: 20px;
}

.konten-text:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: -42px;
    top: 17px;
    /* border: 10px solid transparent;
        border-right: 32px solid rgb(193,193,193); /* IE8 Fallback */
    /* border-right: 32px solid rgba(193,193,193,0.5);  */
    z-index: 2;
}

.konten-text:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: -45px;
  top: 180px;
  border: 15px solid transparent;
  border-right: 30px solid #E29CFF;
  z-index: 3;
}


#akhiri-quiz .modal-content{
    background-color: #222222;
     font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


#akhiri-quiz .modal-content{
    border-radius: 10px !important;
    background-color: #1F1F1F;
}

#akhiri-quiz .modal-content{
    border-radius: 10px !important;
    background-color: #1F1F1F;
}

button {
    box-shadow: none !important;
}
</style>
