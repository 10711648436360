<template>
    <section class="leaderboard-final text-white">
        <h3>Quiz is Over</h3>
        <h5 class="font-weight-normal mt-2">See who gets the highest score on your Quiz!</h5>
        <div class="card parent-card mx-4 mt-4">
            <div class="card-body">
                <div class="leader mt-4" :class="tipeQuiz != 'ramean' ? 'mx-5 px-5' : ''">
                    <div v-if="tipeQuiz == 'seru-seruan'">
                        <div class="d-flex justify-content-between mb-3">
                            <h4 class="text-left">Leaderboard</h4>
                            <div class="d-inline-flex align-items-center">
                                Show&nbsp;
                                <select class="form-control"
                                    v-model="perPage"
                                >
                                    <option v-for="data in pageOptions" :key="data" :value="data">{{ data }}</option>
                                </select
                                >&nbsp;entries
                            </div>
                        </div>
                        <div v-for="(data, index) in listPeserta" :key="index">
                            <div class="card card-inner mb-3">
                                <img v-if="data.index == 0" src="@/assets/images/logo/ke-1.svg" alt="" class="position-absolute float-left ml-2">
                                <img v-else-if="data.index == 1" src="@/assets/images/logo/ke-2.svg" alt="" class="position-absolute float-left ml-2">
                                <img v-else-if="data.index == 2" src="@/assets/images/logo/ke-3.svg" alt="" class="position-absolute float-left ml-2">
                                <div class="card-body py-2">
                                    <div class="row align-items-center ml-4">
                                        <div class="col-md-3">
                                            <div class="d-flex align-items-center user-rank">
                                                <p class="mb-0 mr-3"># {{data.index+1}}</p>
                                                <img :src="'https://ui-avatars.com/api/?background=405DC3&color=fff&name=' + data.user_name" alt="" class="rounded-circle pict-sm">
                                                <p class="m-0 ml-3 mr-1 text-truncate">{{ data.user_name }}</p>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="progress mr-xl-5">
                                                <div class="progress-bar bg-success" role="progressbar" :style="{width: (data.data_true_answer.length / data.data_all_answer.length) * 100 + '%', ariaValuenow : '25', ariaValuemin: '0', ariaValuemax:'100'}">Correct {{data.data_true_answer.length}}</div>
                                                <div class="progress-bar bg-danger" role="progressbar" :style="{width: (data.data_false_answer.length / data.data_all_answer.length) * 100 + '%', ariaValuenow : '25', ariaValuemin: '0', ariaValuemax:'100'}">Incorrect {{data.data_false_answer.length}}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="d-flex justify-content-end align-items-center mr-4 scoring">
                                                <img src="@/assets/images/logo/piala.svg" alt="" class="">
                                                <p class="m-0 ml-3 text-success font-weight-bold">{{data.score}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pagination-seeall container-md mt-3 d-flex justify-content-between">
                            <p>Showing {{listPeserta.length}} of {{leaderboardSeruSeruan.length}} entries</p>
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="rows"
                                :per-page="perPage"
                                aria-controls="allData"
                                prev-text="Prev"
                                next-text="Next"
                            ></b-pagination>
                        </div>
                    </div>
                    <div v-else-if="tipeQuiz == 'ramean'" class="ramean-lead">
                        <div class="d-flex justify-content-between mb-3 mr-3">
                            <h4 class="text-left">Leaderboard</h4>
                            <!-- <div class="d-inline-flex align-items-center">
                                Show&nbsp;
                                <b-form-select
                                    v-model="perPageTeam"
                                    :options="pageOptions"
                                ></b-form-select
                                >&nbsp;entries
                            </div> -->
                        </div>
                        <div v-for="(data, index) in listTeam" :key="index">
                            <div class="card card-inner mb-3 mr-3">
                                <img v-if="data.index == 0" src="@/assets/images/logo/ke-1.svg" alt="" class="position-absolute float-left ml-2">
                                <img v-else-if="data.index == 1" src="@/assets/images/logo/ke-2.svg" alt="" class="position-absolute float-left ml-2">
                                <img v-else-if="data.index == 2" src="@/assets/images/logo/ke-3.svg" alt="" class="position-absolute float-left ml-2">
                                <div class="card-body py-2">
                                    <div class="row align-items-center ml-4">
                                        <div class="col-md-3">
                                            <div class="d-flex align-items-center user-rank">
                                                <h5 class="mb-0 mr-3"># {{data.index+1}}</h5>
                                                <img :src="require(`@/assets/images/users/default_t${index+1}.svg`)" alt="" class="rounded-circle pict-sm">
                                                <h5 class="m-0 ml-3 mr-1 text-truncate">{{ data.team_name }}</h5>
                                            </div>
                                        </div>
                                        <!-- <div class="col-6">
                                            <div class="progress mr-xl-5">
                                                <div class="progress-bar bg-success" role="progressbar" :style="{width: (data.data_true_answer.length / data.data_all_answer.length) * 100 + '%', ariaValuenow : '25', ariaValuemin: '0', ariaValuemax:'100'}">Benar {{data.data_true_answer.length}}</div>
                                                <div class="progress-bar bg-danger" role="progressbar" :style="{width: (data.data_false_answer.length / data.data_all_answer.length) * 100 + '%', ariaValuenow : '25', ariaValuemin: '0', ariaValuemax:'100'}">Salah {{data.data_false_answer.length}}</div>
                                            </div>
                                        </div> -->
                                        <div class="col-md-9">
                                            <div class="d-flex justify-content-end align-items-center mr-4 scoring">
                                                <p class="m-0 ml-3 text-white font-weight-bold">{{bulatkan(data.all_score_team)}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="">
                                        <div v-for="(datPlayer, player) in data.data_player_team" :key="player" class="row align-items-center ml-4 mt-3">
                                            <div class="col-md-3">
                                                <div class="d-flex align-items-center user-rank">
                                                    <img :src="'https://ui-avatars.com/api/?background=405DC3&color=fff&name=' + datPlayer.user_name" alt="" class="rounded-circle pict-sm">
                                                    <p class="m-0 ml-3 mr-1 text-truncate">{{ datPlayer.user_name }}</p>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="progress mr-xl-5">
                                                    <div class="progress-bar bg-success" role="progressbar" :style="{width: (datPlayer.true_answer / (datPlayer.true_answer + datPlayer.false_answer) ) * 100 + '%', ariaValuenow : '25', ariaValuemin: '0', ariaValuemax:'100'}">Correct {{datPlayer.true_answer}}</div>
                                                    <div class="progress-bar bg-danger" role="progressbar" :style="{width: (datPlayer.false_answer / (datPlayer.true_answer + datPlayer.false_answer)) * 100 + '%', ariaValuenow : '25', ariaValuemin: '0', ariaValuemax:'100'}">Incorrect {{datPlayer.false_answer}}</div>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="d-flex justify-content-end align-items-center mr-4 scoring">
                                                    <img src="@/assets/images/logo/piala.svg" alt="" class="">
                                                    <p class="m-0 ml-3 text-success font-weight-bold">{{datPlayer.score}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="container-fluid mt-3 pt-3 pb-5 d-flex justify-content-center align-items-center">
                        <div class="w-100">
                            <h4 class="text-left mb-4">Leaderboard</h4>
                            <div v-if="leaderboardDuel.length>0" class="">
                                <div  class="d-flex justify-content-center align-items-center position-relative">
                                <div class="position-absolute d-flex justify-content-center">
                                    <img src="@/assets/images/logo/versus.svg" alt="">
                                </div>
                                <b-progress height="3rem" :value="leaderboardDuel[0].team_score" :max="maxDuel" show-value class=" w-100 d-flex flex-row-reverse "></b-progress>
                                <b-progress height="3rem" :value="leaderboardDuel[1].team_score" variant="warning" :max="maxDuel" show-value class=" w-100"></b-progress>
                                </div>
                                <div class="d-flex justify-content-between pt-4 pb-5">
                                <div>
                                    <h4 class="text-white">Duel 1</h4>
                                    <div v-for="(data, index) in leaderboardDuel[0].data_player_duel" :key="index" class="d-flex align-items-center mt-3">
                                        <img :src="'https://ui-avatars.com/api/?background=405DC3&color=fff&name=' + data.user_name" class="img-peserta rounded-circle" alt="">
                                        <p class="mb-0 ml-2 text-white">{{ data.user_name }}</p>
                                    </div>
                                </div>
                                <div>
                                    <h4 class="text-white">Duel 2</h4>
                                    <div v-for="(data, index) in leaderboardDuel[1].data_player_duel" :key="index" class="d-flex align-items-center mt-3">
                                        <img :src="'https://ui-avatars.com/api/?background=405DC3&color=fff&name=' + data.user_name" class="img-peserta rounded-circle" alt="">
                                        <p class="mb-0 ml-2 text-white">{{ data.user_name }}</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button class="btn btn-primary btn-lg mt-3 btn-purple" @click="$router.push({ name: 'Report' })">View Quiz Report</button>
    </section>
</template>

<script>
import $ from "jquery"
import {getQuizResultsByEvent} from '@/services/quiz-results/quiz-results.service'
import {getQuizTeamLeaderboard} from '@/services/quiz-results/quiz-results.service'
import {getQuizDuelLeaderboard} from '@/services/quiz-results/quiz-results.service'

export default {
   data(){
    return {
        pageOptions: [5, 10, 25, 50, 100],
        perPage: 5,
        currentPage : 1,
        perPageTeam : 5,
        currentPageTeam : 1,
        leaderboardSeruSeruan : [],
        leaderboardTeam : [],
        leaderboardDuel : [],
        tipeQuiz : null,
        timer : null,
    }
   },
   mounted(){
        $("section.leaderboard-final").parent().addClass('mx-2');
        const tipe = this.$route.query.key
        this.tipeQuiz = tipe;

        this.getLeaderboard(tipe)
   },
   methods:{
    getLeaderboard(tipe){
      const id = this.$route.query.id
      if(tipe == 'seru-seruan'){
        getQuizResultsByEvent(id) 
        .then((response) => {
            if (response.status && response.status == true){
                let index = 0
                let data = response.data
                data.forEach((e) =>{
                    e.index = index
                    this.leaderboardSeruSeruan.push(e)
                    index++;
                })
            } else {
                console.log(response)
            }
        })
      } else if(tipe == 'ramean') {
        getQuizTeamLeaderboard(id)
        .then((response) =>{
           if(response.status && response.status == true){
                let index = 0
                let data = response.data
                data.forEach((e) =>{
                    e.index = index
                    this.leaderboardTeam.push(e)
                    index++;
                })
            }
        })
      } else {
        getQuizDuelLeaderboard(id)
        .then((response) => {
            if(response.status && response.status == true){
            this.leaderboardDuel = response.data
            }
        })
      }
    },
    bulatkan(item){
      var pembulatan = Math.ceil(item)

      return pembulatan
    },
   },
   beforeDestroy(){
    clearInterval(this.timer)
  },
  computed: {
    rows(){
        return this.leaderboardSeruSeruan.length
    },

    listPeserta(){
        return this.leaderboardSeruSeruan.slice((this.currentPage -1) * this.perPage, this.currentPage * this.perPage)
    },

    rowsTeam(){
        return this.leaderboardTeam.length
    },

    listTeam(){
        return this.leaderboardTeam.slice((this.currentPageTeam -1) * this.perPageTeam, this.currentPageTeam * this.perPageTeam)
    },
  }
}
</script>

<style scoped>
.pict-sm{
    height: 33px;
    width: 33px;
}

h3 {
    font-size: 28px !important;
}

h5{
    font-size: 24px !important;
}

h4 {
    font-size: 22px;
}

.scoring p{
    font-size: 20px;
}

.user-rank p{
    font-size: 16px;
}

.parent-card{
    border-radius: 20px;
}

.parent-card .card{
    border-radius:8px;
}

.btn-purple {
    background-color: #6D75F6;
    border: 1px solid #6D75F6;
}

.img-peserta{
    width: 23px;
    height: 23px;
}

.ramean-lead {
    height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.ramean-lead::-webkit-scrollbar {
    width: 6px;
    padding-left: 10px;
}

        /* Track */
.ramean-lead::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 4px;
}
        
        /* Handle */
.ramean-lead::-webkit-scrollbar-thumb {
    background: #B6B6B6; 
    border-radius: 4px;
}

        /* Handle on hover */
.ramean-lead::-webkit-scrollbar-thumb:hover {
    background: #888; 
}

.ramean-lead h5{
    font-size: 20px !important;
}

.form-control{
    background-color: #222222;
    color: white;
    border-color: #383838;
}

.card-inner{
    background-color: #2F2F2F
}
</style>